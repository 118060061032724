/*
 * File: error.tsx
 * Project: meki
 * File Created: Wednesday, 20th January 2021 10:45:04 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 17th November 2023 4:29:20 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import Image from 'next/image';
import { Header } from '@components/Header';
import {
  RootDiv,
  ErrorContainer,
  IconContainer,
  TextTypography,
  LinkTypography,
  SmallTypography,
  LinkContainer,
  MainTextContainer,
} from './error.styles';

export const ErrorScreen = () => {
  return (
    <>
      <Header alwaysElevation fixedPosition={true} />
      <RootDiv>
        <ErrorContainer>
          <IconContainer>
            <Image
              src="/assets/images/errorIcon.webp"
              alt="error"
              layout="fill"
              objectFit="contain"
              priority
            />
          </IconContainer>
          <MainTextContainer>
            <TextTypography>
              Ha ocurrido un problema y estamos trabajando para solucionarlo
            </TextTypography>
          </MainTextContainer>

          <LinkContainer>
            <LinkTypography
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=56977212932&text=${encodeURI(
                    `Hola! Tuve un problema con la pagina. Necesito ayuda con `,
                  )}`,
                  '_blank',
                );
              }}
            >
              Pincha aquí
            </LinkTypography>
            <SmallTypography>
              si quieres recibir ayuda de un asistente
            </SmallTypography>
          </LinkContainer>
        </ErrorContainer>
      </RootDiv>
    </>
  );
};
